<template>
  <dash-page-new
      :root="$store.state.settings.pageData.root || $t('AllCourses')"
      :root-location="$store.state.settings.pageData.rootLocation || businessDashLink('courses')"
      :subtitle="$store.state.settings.pageData.subtitle || $store.state.courses.course.name"
      :title="$store.state.settings.pageData.title || $t('Course_')"
      :icon="$store.state.settings.pageData.icon || 'mdi-cogs'"
      :filters="$store.state.settings.pageData.filters || []"
      :filters-search-ajax="$store.state.settings.pageData.filtersSearchAjax || null"
      :filters-search="$store.state.settings.pageData.filtersSearch || false"
      :header-route="$store.state.settings.pageData.headerRoute || null"
      :filters-input="$store.state.settings.filtersData || {}"
      :side-navigation="courseNavigation"
      @filter="$store.state.settings.filtersData = $event"
      :navigation-route="navigationRoute"
      :navigation-return-route="navigationReturnRoute"
      v-model="$store.state.courses.navigationAdmin"
      :width="$store.state.settings.pageData.width || null"
      :loading="$store.state.courses.selectedLang === null"
  >
    <template #header_action.mobile>
      <portal-target name="dash_course.header" >
        <v-btn icon :color="wsATTENTION"><v-icon>mdi-plus</v-icon></v-btn>
      </portal-target>
    </template>
    <template #header_action.desktop>
      <portal-target name="dash_course.header" >
      <ws-toggle
          v-if="$store.state.settings.pageData.toggle"
          v-model="$store.state.evaluation.displayAllComponentsEvaluation"
          :text-true="$t('All')"
          :text-false="$t('OnlyTasks')"
          :width="!SM ? 260 : '100%'"
          :class="SM ? 'mt-3' : null"
      >
      </ws-toggle>
      <template v-if="$store.state.settings.pageData.headerAction && ($store.state.settings.pageData.headerActionCondition === null || $store.state.settings.pageData.headerActionCondition)">

        <ws-button
            @click="$store.state.settings.pageData.headerAction()"
            :label-raw="$store.state.settings.pageData.headerActionText"
        />

      </template>
      </portal-target>
    </template>

    <template #pre_header>
      <v-sheet color="transparent" max-width="760" width="100%" class="d-flex justify-space-between py-2">

        <div class="d-flex align-center mr-3">
          <h5 class="mr-2">
          {{ $store.state.courses.course.active ? $t('CourseActive') : $t('CourseNotActiveWarning') }}
          </h5>
          <h5  v-if="!$store.state.courses.course.active"
               @click="publishCourse"
               class="pointer"
               style="text-decoration: underline">
            {{ $t('Publish') }}
          </h5>
        </div>

        <ftSelect
            v-if="$store.state.courses.selectedLang"
            v-model="$store.state.courses.selectedLang"
            :items="langsSelect"
        >
          <h5 class="mr-16 d-flex">
            <span v-if="!SM">{{ $t("Lang") }}:</span>
            <span class="font-weight-bold">{{ $store.state.courses.selectedLang.toUpperCase() }}</span>
            <v-icon style="margin-top : -2px" small>mdi-chevron-down</v-icon>
          </h5>
        </ftSelect>

        <div v-if="!$store.state.business.selectedBusiness.disable_public" class="d-flex align-center">
          <router-link
              :style="`color : ${$store.state.courses.course.active ? 'white' : wsBACKGROUND } !important`" class="noUnderline" target="_blank"
              :text="$t(`courses.landing.course_not_active_tooltip`)"
              :is="!$store.state.courses.course.active ? 'ws-tooltip' : 'router-link'"
              :to="businessPublicLink(coursePage ? `page/${coursePage}` : `course${$store.state.business.selectedBusiness.disable_public ? '/enroll' : ''}/${uuid}`) ">
            <h5
                :style="`color : ${$store.state.courses.course.active ? 'white' : wsBACKGROUND } !important`"
                style="line-height: 1;"  class="d-flex align-center">
              <span
                  v-if="!SM"
                  :style="$store.state.courses.course.active ? `text-decoration: underline` : null ">
                {{ $t($store.state.business.selectedBusiness.disable_public ? 'CourseRegisterLink' : 'GotoCourseLandingPage') }}</span>
              <v-icon
                  v-if="$store.state.courses.course.active"
                  :style="`opacity : ${$store.state.courses.course.active ? 1 : 0}`"
                  class="ml-2" size="18">mdi-open-in-new</v-icon>
            </h5>
          </router-link>
          <router-link
              v-if="coursePageId"
              class="noUnderline"
              target="_blank"
              :to="businessPublicLink(`page/${coursePageId}/editor`)"   >
            <v-icon  class="ml-2" size="18">mdi-pencil</v-icon>
          </router-link>
        </div>


      </v-sheet>
    </template>

    <template #default>
      <v-sheet :max-width="$store.state.settings.pageData.width || null" class="fill-height">
        <router-view :publish-trigger="publishTrigger"></router-view>
      </v-sheet>
    </template>


  </dash-page-new>
</template>

<script>

import {mapActions , mapMutations} from "vuex";
import languagesList from "@/mixins/languagesList";

export default {
  name: "DashCourse",
  props : ['uuid'],
  mixins : [languagesList],
  data() {
    return {
      course : {},
      uploadLogo : false,
      logoUrl : null,
      publishTrigger : 0,
      coursePage : null,
      coursePageId : null,
      isActive : false
    }
  },
  computed : {
    langsSelect() {
      let items = []
      let langs = this.$store.state.courses.course.langs || ['ua']

      langs.forEach(lang => {
        items.push({ value : lang , text : this.LANGUAGE_TEXT(lang)})
      })

      return items
    },
    navigationReturnRoute() {
      if ( this.$store.state.settings.pageData.sideNavigation && this.$store.state.settings.pageData.sideNavigation.length > 0  ) {
        return `courses/${this.uuid}/editor`
      }
          return 'courses'
    },
    navigationRoute() {
      if ( this.$store.state.settings.pageData.sideNavigation && this.$store.state.settings.pageData.sideNavigation.length > 0  ) {
        return `courses/${this.uuid}/${this.$store.state.settings.pageData.navigationRoute}`
      }
      return `courses/${this.uuid}`
    },


    courseNavigation() {
      if ( this.$store.state.settings.pageData.sideNavigation && this.$store.state.settings.pageData.sideNavigation.length > 0  ) {
        return this.$store.state.settings.pageData.sideNavigation
      }
      return this.COURSE_NAVIGATION_ADMIN(this.$store.state.courses.course)
    }
  },
  methods : {
    ...mapActions('courses', [ 'GET_COURSE' , 'PUBLISH_COURSE' ]),
    ...mapActions('upload', [ 'UPLOAD_COURSE_IMAGE',  ]),
    ...mapMutations('courses' , ['INIT_PAGE']),
    ...mapActions('evaluation',["GET_STUDENT_NEW_REPLIES_COUNT"]),


    async updateLogo(file) {
      let data = {
        file : file,
        data : {
          course : this.uuid
        },
        entityDataDefault : {
          name : {},
          description : {},
          short_description: {},
          img : null,
        }
      }
      let result = await this.UPLOAD_COURSE_IMAGE(data)
      if ( !result ) { return this.notify('ConnectionError') }

      this.$store.state.courses.course.img = result.url
      this.uploadLogo = false
    },
    async publishCourse() {
      let result = await this.PUBLISH_COURSE(this.uuid)
      if (!result) { return this.notify( this.$t('NetworkError') )}
      this.$store.state.courses.course.active = true
      this.notify( this.$t('CoursePublished')  , 'success')
      this.publishTrigger++

    },
    async initPage() {

      this.$store.state.dashboard.pageNavigationReturnRoute = 'courses'
      this.$store.state.courses.course = await this.GET_COURSE({ uuid : this.uuid, lang : this.$store.state.courses.selectedLang} )
      if ( !this.$store.state.courses.selectedLang ) {
        this.$store.state.courses.selectedLang = this.$store.state.courses.course.lang
      }
      this.coursePage = this.$store.state.courses.course.page
      this.coursePageId = this.$store.state.courses.course.page_id
      this.isActive = this.$store.state.courses.course.active

      let langs = this.$store.state.courses.course.langs || [ 'ua' ]
      if ( !langs.includes(this.$store.state.courses.selectedLang) ) {
        this.$store.state.courses.selectedLang = this.$store.state.courses.course.lang
      }

      let result = await this.GET_STUDENT_NEW_REPLIES_COUNT(this.uuid)
      this.$store.state.courses.courseNewRepliesCounter = result ? result.replies_count : 0
    }
  },
  mounted() {
    this.initPage()
  },
  beforeDestroy() {
    this.$store.state.courses.selectedLang = null
  }

}
</script>

<style scoped>

</style>